import React from 'react';
import TextLinkButton from './TextLinkButton';

import * as style from './Error.module.css';
import { Link } from 'gatsby';

export default function Home() {
  return (
    <div className={style.container}>
      <div className={style.message}>
        {/* 404 */}
        <img
          src="/images/404dog.svg"
          alt="犬が穴を覗いている、Amebaらしい装飾がされたイラスト"
          className={style.image}
          height="201"
          width="240"
        />
        <h1 className={style.heading}>
          お探しのページは見つかりませんでした。
        </h1>
        <aside>
          <h2 className={style.subheading}>以前のサイトからお越しの方へ</h2>
          <p className={style.text}>
            2021年9月より、新しいサイトへと移転しました。
            <br />
            お手数ですが、上部のナビゲーションからお探しください。
          </p>

          <h2 className={style.subheading}>みんながよく見るページ</h2>
          <ul className={style.linklist}>
            <li>
              <Link to="/brand/guideline">ブランドガイドライン</Link>
            </li>
            <li>
              <Link to="/principles/design">デザイン原則</Link>
            </li>
            <li>
              <Link to="/brand/accessibility">アクセシビリティ</Link>
            </li>
            <li>
              <Link to="/styles/color">カラー</Link>
            </li>
            <li>
              <Link to="/components">コンポーネント</Link>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  );
}
