import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import MenuHamburgerBold from '@openameba/spindle-ui/Icon/MenuHamburgerBold';
import ChevronRight from '@openameba/spindle-ui/Icon/ChevronRight';
import CrossBold from '@openameba/spindle-ui/Icon/CrossBold';
import Openblank from '@openameba/spindle-ui/Icon/Openblank';

import * as style from './Header.module.css';

export const categories = [
  {
    pathname: '/brand/',
    label: 'ブランド',
  },
  {
    pathname: '/principles/',
    label: '原則',
  },
  {
    pathname: '/styles/',
    label: 'スタイル',
  },
  {
    pathname: '/components/',
    label: 'コンポーネント',
  },
  {
    pathname: '/about/',
    label: 'Spindleについて',
  },
  {
    pathname: 'https://twitter.com/AmebaSpindle',
    label: '更新情報',
    external: true,
  },
];

function Logo({
  children,
  pathname,
}: {
  children: React.ReactNode;
  pathname?: string;
}) {
  return pathname === '/' ? (
    <h1 className={style.logo}>{children}</h1>
  ) : (
    <p className={style.logo}>{children}</p>
  );
}

function MenuDialog({
  pathname,
  onClose,
}: {
  pathname?: string;
  onClose: () => void;
}) {
  return (
    <dialog className={style.menuDialog}>
      <div className={style.menuDialogHeader}>
        <img
          className={`${style.menuDialogLogo} theme--light`}
          src="/images/spindle-logo.svg"
          alt="Spindle"
          width="124"
          height="32"
        />
        <img
          className={`${style.menuDialogLogo} theme--dark`}
          src="/images/spindle-logo-inverse.svg"
          alt="Spindle"
          width="124"
          height="32"
        />
        <button className={style.button} onClick={onClose} aria-label="menu">
          <CrossBold className={style.icon} aria-hidden />
        </button>
      </div>
      <ul className={style.list}>
        <Item pathname={pathname} />
      </ul>
    </dialog>
  );
}

function List({ pathname }: { pathname?: string }) {
  return (
    <ul className={style.list}>
      <Item pathname={pathname} />
    </ul>
  );
}

function Item({ pathname }: { pathname?: string }) {
  return (
    <>
      {categories.map((category) => {
        // Check if the page is under the selected category
        const selected = pathname?.startsWith(category.pathname);
        const className = [style.anchor, selected ? style.selected : '']
          .filter(Boolean)
          .join(' ');
        return (
          <li className={style.item} key={category.pathname}>
            {category.external ? (
              <a
                className={className}
                href={category.pathname}
                target="_blank"
                rel="noopener noreferrer"
              >
                {category.label}
                <Openblank
                  className={style.iconExtelnalLink}
                  aria-label="外部サイト"
                />
                <ChevronRight
                  className={`${style.icon} ${style.iconChevron}`}
                  aria-hidden
                />
              </a>
            ) : (
              <Link className={className} to={category.pathname}>
                {category.label}
                <ChevronRight
                  className={`${style.icon} ${style.iconChevron}`}
                  aria-hidden
                />
              </Link>
            )}
          </li>
        );
      })}
    </>
  );
}

export default function GlobalHeader({ pathname }: { pathname?: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleOnCloseDialog = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <nav className={style.nav}>
      <Logo pathname={pathname}>
        <Link to={'/'}>
          <picture className="theme--light">
            <source
              media="(max-width: 768px)"
              srcSet="/images/spindle-motion-logo.png"
              width="124"
              height="32"
            />
            <img
              src="/images/spindle-motion-logo.png"
              alt="Spindle"
              width="183"
              height="50"
            />
          </picture>
          <picture className="theme--dark">
            <source
              media="(max-width: 768px)"
              srcSet="/images/spindle-motion-logo-inverse.png"
              width="124"
              height="32"
            />
            <img
              src="/images/spindle-motion-logo-inverse.png"
              alt="Spindle"
              width="183"
              height="50"
            />
          </picture>
        </Link>
      </Logo>
      <button
        className={style.button}
        onClick={handleOnOpenDialog}
        aria-label="menu"
      >
        <MenuHamburgerBold className={style.icon} aria-hidden />
      </button>
      {isOpen ? (
        <MenuDialog pathname={pathname} onClose={handleOnCloseDialog} />
      ) : (
        <List pathname={pathname} />
      )}
    </nav>
  );
}
