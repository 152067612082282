/// <reference types="@openameba/spindle-theme-switch" />

import React from 'react';
import { Link, Script } from 'gatsby';

import * as style from './Footer.module.css';

const items = [
  {
    pathname: '/about/',
    label: 'Spindleについて',
    children: [
      {
        pathname: '/about/mediakit/',
        label: 'メディアキット',
      },
    ],
  },
  {
    pathname: '/brand/',
    label: 'ブランド',
    children: [
      {
        pathname: '/brand/about/',
        label: 'ブランディングとは',
      },
      {
        pathname: '/brand/ameba-brand/',
        label: 'Amebaブランド',
      },
    ],
  },
  {
    pathname: '/components/',
    label: 'コンポーネント',
    children: [
      {
        pathname: '/components/status/',
        label: 'ステータス',
      },
      {
        pathname: '/components/button/',
        label: 'ボタン',
      },
      {
        pathname: '/components/checkbox/',
        label: 'チェックボックス',
      },
      {
        pathname: '/components/list/',
        label: 'リスト',
      },
      {
        pathname: '/components/modal/',
        label: 'モーダル',
      },
      {
        pathname: '/components/textfield/',
        label: 'テキストフィールド',
      },
    ],
  },
  {
    pathname: '/principles/',
    label: '原則',
    children: [
      {
        pathname: '/principles/design/',
        label: 'デザイン原則',
      },
      {
        pathname: '/principles/accessibility/',
        label: 'アクセシビリティ',
      },
      {
        pathname: '/principles/performance/',
        label: 'パフォーマンス',
      },
      {
        pathname: '/principles/contents/',
        label: 'コンテンツ',
      },
    ],
  },
  {
    pathname: '/styles/',
    label: 'スタイル',
    children: [
      {
        pathname: '/styles/color/',
        label: 'カラー',
      },
      {
        pathname: '/styles/typography/',
        label: 'タイポグラフィ',
      },
      {
        pathname: '/styles/iconography/',
        label: 'アイコン',
      },
      {
        pathname: '/styles/illustration/',
        label: 'イラストレーション',
      },
      {
        pathname: '/styles/animation/',
        label: 'アニメーション',
      },
    ],
  },
];

export default function Footer() {
  return (
    <div className={style.container}>
      <div>
        <aside>
          <p className={style.logo}>
            <picture className="theme--light">
              <source
                media="(max-width: 768px)"
                srcSet="/images/spindle-logo-silver.svg"
                width="184"
                height="48"
              />
              <img
                src="/images/spindle-logo-silver.svg"
                alt="Spindle"
                width="300"
                height="78"
                loading="lazy"
              />
            </picture>
            <picture className="theme--dark">
              <source
                media="(max-width: 768px)"
                srcSet="/images/spindle-logo-silver-inverse.svg"
                width="184"
                height="48"
              />
              <img
                src="/images/spindle-logo-silver-inverse.svg"
                alt="Spindle"
                width="300"
                height="78"
                loading="lazy"
              />
            </picture>
          </p>
          <p className={style.description}>
            SpindleはAmebaを作るすべての人が「Amebaらしさ」をつくるための約束ごとや、それを手助けするツールやガイドラインが揃ったデザインシステムです。
          </p>
          <div className={style.themeSwitch}>
            <spindle-theme-switch
              appearance="switch"
              legend="テーマを切り替える"
            />
            <p aria-hidden="true" className={style.themeSwitchLabel}>
              テーマを切り替える
            </p>
          </div>
          <Script
            src="https://unpkg.com/@openameba/spindle-theme-switch/dist/spindle-theme-switch.js"
            type="module"
          />
          <div className={style.block}>
            {items.map((item) => {
              return (
                <div className={style.category} key={item.pathname}>
                  <h2 className={style.label}>{item.label}</h2>
                  <ul className={style.list}>
                    {item.children && (
                      <>
                        {item.children.map((child) => {
                          return (
                            <li className={style.item} key={child.pathname}>
                              <Link
                                className={style.anchor}
                                to={child.pathname}
                              >
                                {child.label}
                              </Link>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ul>
                </div>
              );
            })}
          </div>
        </aside>
        <div></div>
      </div>
      <p className={style.copyright}>&copy; CyberAgent, Inc.</p>
    </div>
  );
}
