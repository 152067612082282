import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Error from '../components/Error';
import SEO from '../components/SEO';

import 'normalize.css';
import 'ameba-color-palette.css';
import '../components/layout.css';

export default function () {
  return (
    <>
      <SEO />
      <header className="layout-header">
        <div className="layout-header-inner">
          <Header />
        </div>
      </header>

      <Error />

      <footer className="layout-footer">
        <Footer />
      </footer>
    </>
  );
}
