// extracted by mini-css-extract-plugin
export var anchor = "Footer-module--anchor--ce48c";
export var block = "Footer-module--block--d34be";
export var category = "Footer-module--category--ed309";
export var container = "Footer-module--container--b5d4b";
export var copyright = "Footer-module--copyright--0ba15";
export var description = "Footer-module--description--6eb53";
export var item = "Footer-module--item--772c8";
export var label = "Footer-module--label--697b2";
export var list = "Footer-module--list--21d87";
export var logo = "Footer-module--logo--b0f4f";
export var themeSwitch = "Footer-module--themeSwitch--1d280";
export var themeSwitchLabel = "Footer-module--themeSwitchLabel--1d6df";